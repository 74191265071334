import React, { useEffect, useState } from 'react'
import './modal.scss'
import { cmsLanguage } from '../cms/commons/cms_language/cmsLanguage'
import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap'
import STORAGE_KEYS from '../utils/storageKeys'
import { getInitialTACValues } from '../utils/modalTermsAndConditions'
import TermsAndConditionsBody from './TermsAndConditionsBody'

const TermsModal = ({ isMobile, setCustomNavigation }) => {
    const initialTACValues = getInitialTACValues()
    const [isConsentSaved, setIsConsentSaved] = useState(initialTACValues)
    const [isModalVisible, setIsModalVisible] = useState(true)
    const [shouldRefresh, setShouldRefresh] = useState(false)

    const isTACS = (localStorage.getItem(STORAGE_KEYS.IS_TERMS_AND_CONDITIONS_ACCEPTED))

    useEffect(() => {
        const saved = (isTACS || '{}') !== '{}'
        if (saved) {
            setIsConsentSaved(JSON.parse(isTACS))
        } else {
            setIsConsentSaved(initialTACValues)
        }
        setIsModalVisible(!saved)
    }, [])
    if (shouldRefresh && !isModalVisible) {
        setShouldRefresh(false)
    }

    const handleToggle = (id) => () => {
        // e.currentTarget.id
        const newIsConsentSaved = { ...isConsentSaved }
        newIsConsentSaved[id] = !isConsentSaved[id]
        setIsConsentSaved(newIsConsentSaved)
    }

    const handleSave = () => {
        localStorage.setItem(STORAGE_KEYS.IS_TERMS_AND_CONDITIONS_ACCEPTED, JSON.stringify(isConsentSaved))
        setIsModalVisible(false)
        if (isTACS === '') {
            setShouldRefresh(true)
        }
        setCustomNavigation(window.location.pathname.concat(window.location.search))
    }

    if (!isModalVisible) return null
    return (
        <div id={'TermsModal'}>
            {isMobile
                ? (
                    <div className={'terms-and-conditions-container'}>
                        <div className={'terms-header'}>
                            <h1>
                                {cmsLanguage.termsAndConditions.title}
                            </h1>
                        </div>
                        <div className={'terms-body'}>
                            <TermsAndConditionsBody isConsentSaved={isConsentSaved} handleToggle={handleToggle}/>
                        </div>
                        <div >
                            <Button className={'save-button'} variant="success" onClick={handleSave}>
                                {cmsLanguage.termsAndConditions.save}
                            </Button>
                        </div>
                    </div>
                )
                : (
                    <Modal show={isModalVisible} centered>
                        <Modal.Header>
                            <Modal.Title style={{ textAlign: 'center w-100', margin: 'auto' }}>
                                {cmsLanguage.termsAndConditions.title}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <TermsAndConditionsBody isConsentSaved={isConsentSaved} handleToggle={handleToggle}/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={handleSave}>
                                {cmsLanguage.termsAndConditions.save}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )
            }
        </div>
    )
}

export default TermsModal
