import store from '../../../redux/store'
import { CONFIG, MESH_CONFIG } from '../Constants'
// eslint-disable-next-line no-unused-vars
import axios, { AxiosResponse } from 'axios'
import { trackPromise } from 'react-promise-tracker'
import { dummyAppMenuData } from '../../../dummyAppMenuData'
import { sortMenuAlphabetically } from '../helpers/menuSorter'
import { cmsLanguage } from '../../../cms/commons/cms_language/cmsLanguage'
import { initializerMenuData } from '../../../initializerData'

/**
 * MeshContentMenu
 * @class MeshContentMenu
 * @category Backend
 * @subcategory content_service
 */
export default class MeshContentMenu {
    /**
     * It will be used to determine whether to show a dummy menu
     * @function
     * @memberof MeshContentMenu
     * @returns {boolean}
     * @static
     */
    static enableDummy () {
        return false
    }

    /**
     * Fetches the main menu from content-service and returns it
     * @function
     * @memberof MeshContentMenu
     * @returns {Promise<AxiosResponse<Menu>>}
     * @static
     */
    static async fetchMenu () {
        const { accessToken, organizationId } = store.getState().user

        const url = `${MESH_CONFIG.SERVER_URL}/content-service/organizations/${organizationId}/menu/main`

        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        return axios.get(url, config)
    }

    /**
     * Sends a `GET` request to the `content-service` to get a specific menu
     * @function
     * @memberof MeshContentMenu
     * @param {string} menuId
     * @returns {Promise<Menu>}
     * @static
     */
    static async getMenu (menuId) {
        const { accessToken, organizationId } = store.getState().user

        try {
            const res = await trackPromise(
                axios.get(
                    `${MESH_CONFIG.SERVER_URL}/content-service/organizations/${organizationId}/menu/${menuId}`,
                    {
                        headers: {
                            ...CONFIG.DEFAULT_HEADERS,
                            CONTENT_TYPE: 'application/json',
                            Authorization: `Bearer ${accessToken}`
                        }
                    }
                )
            )
            return sortMenuAlphabetically([res.data])[0]
        } catch (e) {
            console.error('Failed to fetch menu by path:', e)
        }
    }

    /**
     * Sends a `GET` request to the `content-service` to get a specific menu
     * @function
     * @memberof MeshContentMenu
     * @param {string} path
     * @returns {Promise<Menu>}
     * @static
     */
    static async getMenuByPath (path) {
        const { accessToken, organizationId } = store.getState().user

        try {
            const res = await trackPromise(
                axios.get(
                    `${MESH_CONFIG.SERVER_URL}/content-service/organizations/${organizationId}/menu/getByPath?path=${path}`,
                    {
                        headers: {
                            ...CONFIG.DEFAULT_HEADERS,
                            CONTENT_TYPE: 'application/json',
                            Authorization: `Bearer ${accessToken}`
                        }
                    }
                )
            )
            return sortMenuAlphabetically([res.data])[0]
        } catch (e) {
            console.log('Something went wrong', e)
        }
    }

    /**
     * Sends a `POST` request to the `content-service` to create a new menu
     * @function
     * @memberof MeshContentMenu
     * @param {Menu} newMenu
     * @returns {Promise<Menu>}
     * @static
     */
    static async createMenu (newMenu) {
        const { accessToken, organizationId } = store.getState().user
        let url = `${MESH_CONFIG.SERVER_URL}/content-service/organizations/${organizationId}/menu`
        if (newMenu instanceof Array) {
            url += '/multiple'
        }
        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const response = await axios.post(url, newMenu, config)
        return response.data
    }

    /**
     * Sends a `PUT` request to the `content-service` to update the received menu
     * @function
     * @memberof MeshContentMenu
     * @param {Menu} menu
     * @returns {Promise<AxiosResponse<Menu>>}
     * @static
     */
    static updateMenu (menu) {
        const { accessToken, organizationId } = store.getState().user
        const url = `${MESH_CONFIG.SERVER_URL}/content-service/organizations/${organizationId}/menu`
        const config = {
            headers: {
                ...CONFIG.DEFAULT_HEADERS,
                CONTENT_TYPE: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        return axios.put(url, menu, config)
    }

    /**
     * Fetches menu from content-service, sort it and build a MenuData structure to return
     * @function
     * @memberof MeshContentMenu
     * @returns {Promise<MenuData>}
     * @static
     */
    static async fetchAppMenu () {
        if (MeshContentMenu.enableDummy()) {
            return dummyAppMenuData
        }
        let menuData = {}
        try {
            const response = await MeshContentMenu.fetchMenu()
            menuData = sortMenuAlphabetically(response.data)
        } catch (error) {
            if (error.response.status === 404) {
                console.info(cmsLanguage.error.pendingInitializeMenus)
            } else {
                console.error(error)
            }
            return {
                topMenu: null,
                headerMenu: null,
                homeMenu: null,
                footerMenu: null,
                socialMediaMenu: null
            }
        }

        return {
            topMenu: menuData.find(menu => menu.path === initializerMenuData.topMenu.path),
            headerMenu: menuData.find(menu => menu.path === initializerMenuData.headerMenu.path),
            homeMenu: menuData.find(menu => menu.path === initializerMenuData.homeMenu.path),
            footerMenu: menuData.find(menu => menu.path === initializerMenuData.footerMenu.path),
            socialMediaMenu: menuData.find(menu => menu.path === initializerMenuData.socialMediaMenu.path)
        }
    }
}
