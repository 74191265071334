import React from 'react'
import { cmsLanguage } from '../cms/commons/cms_language/cmsLanguage'

const TermsAndConditionsBody = ({ isConsentSaved, handleToggle }) => {
    const TaCs = cmsLanguage.termsAndConditions.content
    const introduction = TaCs.introduction.content
    const dataProtectionAndTools = TaCs.dataProtectionAndTools.content

    return <>
        <h3>{introduction.title}</h3>
        <p>{introduction.description}</p>
        <h3>{dataProtectionAndTools.title}</h3>
        <p>{dataProtectionAndTools.description}</p>
        <ul>
            {dataProtectionAndTools.list.map(
                (item, index) => (
                    <div key={'terms-container' + index} className="terms-container">
                        <div key={'terms-block' + index} className="terms-block">
                            <li key={'li' + index}>
                                <strong>{item.title}</strong> {item.content.description}
                            </li>
                        </div>
                        <div key={'button-block' + index} className="button-block">
                            <label key={'switch' + index} className="switch">
                                <input key={'input' + index}
                                    role={'checkbox'.concat('-').concat(item.id)}
                                    type="checkbox"
                                    checked={isConsentSaved[item.id]}
                                    onChange={handleToggle(item.id)}
                                />
                                <span key={'span' + index} className="slider round"></span>
                            </label>
                        </div>
                    </div>
                ))}
        </ul>
        <h3>{TaCs.consentManagement.title}</h3>
        <p>{TaCs.consentManagement.content.description}</p>
        <h3>{TaCs.disclaimer.title}</h3>
        <p>{TaCs.disclaimer.content.description}</p>
        <h3>{TaCs.updates.title}</h3>
        <p>{TaCs.updates.content.description}</p>
    </>
}

export default TermsAndConditionsBody
