import React, { useEffect, useState } from 'react'
import { Route, Router, Switch, useHistory } from 'react-router-dom'
import Template1 from '@templates/template_1/TemplateContainer'
import './navigation.scss'
import PageError404 from '../page_error_404/PageError404'
import { popPathElement } from '../utils/CommonReformatter'
import { FullNewsPage } from '@pages'
import NewsTemplate from '@templates/news_template/NewsTemplate'
import PromptBlockNavigation
    from '../cms/commons/prompt_block_navigation/PromptBlockNavigationContainer'
import { closestString } from '../utils/stringUtils'
import MeshContentMenu from '../backend/mesh/content_service/MeshContentMenu'
import { initPathname } from '../utils/getPathWithoutInitialSlashAndEndSlash'
/**
 * @typedef NavigationProps
 * @memberOf Navigation
 * @property {Menu} menu
 * @property {Menu} [pageMenu]
 * @property {Menu} [customNavigation]
 * @property {function} [setCustomNavigation]
 * @property {function} [setCurrentRoutes]
 * @property {string} [className]
 * @property {Menu} [currentContentMenu]
 */

/**
 * Menu item component
 * @class Navigation
 * @category Components
 * @subcategory Navigation
 * @param {NavigationProps} props
 * @returns {React.ReactNode}
 * @example
 * <Navigation customNavigation={customNavigation} menu={menu} className={!!isAdminBarVisible && 'body-admin-margin'} />
 */
const Navigation = (props) => {
    const { user, menu, pageMenu, customNavigation, setCustomNavigation, setCurrentRoutes, className, currentContentMenu } = props
    const [currentRoutesLocal, setCurrentRoutesLocal] = useState([])

    const [pathname] = useState(initPathname(window.location.pathname))
    const history = useHistory()

    useEffect(() => {
        let timeOut = null
        if (customNavigation?.path && window.location.pathname !== customNavigation.path) {
            const closestPath = closestString(currentRoutesLocal.map(it => it.route), customNavigation.path)
            console.debug(`closest: ${closestPath}`)
            timeOut = setTimeout(() => {
                history.push(closestPath, customNavigation.state)
                setCustomNavigation(null)
            }, customNavigation.path === '/' ? 0 : 100)
        }
        return () => timeOut && clearTimeout(timeOut)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customNavigation, currentRoutesLocal])

    useEffect(async () => {
        const topMenuRoutes = addMenuRoutes(menu?.topMenu, false)
        const headerRoutes = addMenuRoutes(menu?.headerMenu, false)
        const footerRoutes = addMenuRoutes(menu?.footerMenu, false)
        const pageMenuRoutes = addMenuRoutes(pageMenu || currentContentMenu, true)

        const withoutDuplicates = [
            ...topMenuRoutes,
            ...headerRoutes,
            ...footerRoutes,
            ...pageMenuRoutes
        ].reduce((acc, newRoute) => {
            if (acc.find((route) => route.route === newRoute.route) === undefined) {
                acc.push(newRoute)
            }
            return acc
        }, [])

        if (withoutDuplicates.find((route) => route.route === pathname) ===
            undefined) {
            if (user.accessToken) {
                const menuData = await (MeshContentMenu.getMenuByPath(pathname))
                if (menuData && menuData.contentId) {
                    withoutDuplicates.push({
                        path: pathname,
                        contentId: menuData.contentId
                    })
                }
            }
        }

        setCurrentRoutes(withoutDuplicates)
        setCurrentRoutesLocal(withoutDuplicates)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menu, pageMenu])

    const addMenuRoutes = (menu = {}, isSubpageMenu) => {
        const routes = []
        if (!menu) {
            return []
        }
        if (
            menu.path &&
            menu.path !== 'contact_us' &&
            menu.contentId &&
            menu.contentId !== '' &&
            menu.name !== 'Contact Us'
        ) {
            routes.push({
                route: ('/' + (isSubpageMenu ? menu.path : popPathElement(menu.path))),
                contentId: menu.contentId
            })
        }
        menu.children &&
        menu.children.forEach((child) => {
            const newRoutes = isSubpageMenu
                ? addMenuRoutes(child, true)
                : addMenuRoutes(child, false)
            routes.push(...newRoutes)
        })
        return routes
    }

    return (
        <Router history={history}>
            <div className={`body ${className}`}>
                <Switch>
                    <Route
                        exact
                        path={'/'}
                        render={() => <Template1 contentId={menu?.homeMenu?.contentId} >
                            <PromptBlockNavigation />
                        </Template1>}
                    />
                    <Route exact path={'/news'} render={() => (
                        <NewsTemplate>
                            <PromptBlockNavigation />
                        </NewsTemplate>
                    )}/>
                    <Route path={'/news/:newsId'} component={FullNewsPage} />
                    {currentRoutesLocal.length > 0 && currentRoutesLocal.map((item, index) => {
                        return (
                            <Route
                                exact
                                path={item.route}
                                key={index}
                                render={() => (
                                    <Template1 contentId={item.contentId || currentContentMenu.id} >
                                        <PromptBlockNavigation />
                                    </Template1>
                                )}
                            />
                        )
                    })}
                    <Route component={PageError404} />
                </Switch>
            </div>
        </Router>
    )
}

export default Navigation
