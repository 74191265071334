/**
 * @module getPathWithoutInitialSlashAndEndSlash
 * @category Utils
 * @subcategory General
 */

/**
 * Takes a string and returns it with the first letter capitalized
 * @function
 * @param {string} string
 * @returns {string}
 * @example
 * const text = '/sports/ball_and_spielsportarte/badminton/abteilung/'
 * const name = initPathname(string) // 'sports/ball_and_spielsportarte/badminton/abteilung'
 */
export const initPathname = (text) => {
    let locationPathname = text
    const firstIndexOf = locationPathname.indexOf('/')
    if (firstIndexOf === 0) {
        locationPathname = (locationPathname.substring(1, locationPathname.length))
    }
    const lastIndexOf = locationPathname.lastIndexOf('/')
    if (lastIndexOf === (locationPathname.length - 1)) {
        locationPathname = (locationPathname.substring(0, locationPathname.length - 1))
    }

    return locationPathname
}
