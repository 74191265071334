/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { trackPromise } from 'react-promise-tracker'
import GreyBanner from '../grey_banner/GreyBanner'
import HomeBanner from '../../home/home-banner/HomeBanner'
import HomeNews from '../../home/components/home_news/HomeNews'
import SportPicturesBanner from '../../home/components/sport_pictures_banner/SportPicturesBanner'
import HomeAboutUs from '../../home/components/home_about_us/HomeAboutUs'
import HomeSponsors from '../../home/components/home_sponsors/HomeSponsors'
import CommonBanner from '../../theme/commons/common_banner/CommonBanner'
import NewsTemplate from '../news_template/NewsTemplate'
import PhotoGalleryTemplate from '../photo_gallery_template/PhotoGalleryTemplate'
import AccordionTemplate from '../accordion_template/AccordionTemplate'
import ActionButtonBanner from '../action_button_banner/ActionButtonBanner'
import ProfilesTemplate from '../profiles_template/ProfilesTemplate'
import MembersAreaContainer from '../members-area/MembersAreaContainer'
import PhotoGalleryBanner from '../photo_gallery_banner/PhotoGalleryBanner'
import TextTemplate from '../text_template/TextTemplate'
import CmsTemplatesBars from '../../cms/cms_templates_bars/CmsTemplatesBarsContainer'
import ContactTemplate from '../contact-template/ContactTemplate'
import MeshContent from '../../backend/mesh/content_service/MeshContent'
import VideoTemplate from '../video_template/VideoTemplate'
import profileImage from '../../theme/assets/FrontPage1.jpg'
import TrainingPage from '../../pages/trainingSchedule/TrainingContainer'
import { reducedElementsPro } from '../../utils/elementsUtils'
import MembersForm from '../../pages/become-a-member/MembersFormContainer'
import CourseInfoContainer from '../course_info'
import adaptCourseData from '../course_info/adaptCourseData'
import Instagram from '../instagram/Instagram'
import { templateTypes } from '../../cms/cms_templates_bars/components/cms_modal_multiple_elements/constants'
import { Helmet } from 'react-helmet'
import { CONTENT_FOOTER, CONTENT_SOCIAL_MEDIA } from '../../cms/types'

/**
 * @typedef Template1Props
 * @memberOf Template1
 * @property {string} [contentId]
 * @property {Object} [content]
 * @property {Object} [contentDB]
 * @property {boolean} [isEditModeOn]
 * @property {function} [addTemplate]
 * @property {function} [updateTemplate]
 * @property {function} [deleteTemplate]
 * @property {function} [addMultipleProduct]
 * @property {function} [setContent]
 * @property {function} [toggleTrainingPage]
 * @property {function} [setContent]
 * @property {function} [setContent]
 * @property {function} [setContentMenu]
 * @property {function} [setIsHomePage]
 * @property {function} [toggleTrainingPage]
 * @property {React.ReactNode} [children]
 */

/**
 * Template component base.
 *  - [AccordionTemplate]{@link AccordionTemplate}
 *  - [ActionButtonBanner]{@link ActionButtonBanner}
 *  - [ContactTemplate]{@link ContactTemplate}
 *  - [CourseInfo]{@link CourseInfo}
 *  - [GreyBanner]{@link GreyBanner}
 *  - [Instagram]{@link Instagram}
 *  - [MembersArea]{@link MembersArea}
 *  - [NewsTemplate]{@link NewsTemplate}
 *  - [PhotoGalleryBanner]{@link PhotoGalleryBanner}
 *  - [PhotoGalleryTemplate]{@link PhotoGalleryTemplate}
 *  - [ProfilesTemplate]{@link ProfilesTemplate}
 *  - [TextTemplate]{@link TextTemplate}
 *  - [VideoTemplate]{@link VideoTemplate}
 *
 * @class Template1
 * @category Components
 * @subcategory Templates
 *
 * @mermaid
 *   sequenceDiagram
 *   Navigation->>Template1: Render Template1 bind with a route and passes the 'contentID'
 *   Template1->>contentService: Request content data sending the contentID.
 *   contentService-->>Template1: Sends back the content data.
 *   Template1-->>reduxStore: Sends the content data.
 *   Template1-->>Component: based on 'template.type', load a specific Template Component.
 *   Component-->>Template1: Component is loaded.
 *   Template1-->>Navigation: Template1 is rendered on the page.
 *
 * @param {Template1Props} props
 * @returns {React.ReactNode}
 * @example
 * <Template1 contentId={item.contentId || currentContentMenu.id}>
 *     <PromptBlockNavigation />
 * </Template1>
 *
 *
 */
const Template1 = (props) => {
    const currentUrl = `${window.location.origin}${window.location.pathname}`
    const {
        contentId,
        content,
        isEditModeOn
    } = props

    const addTemplate = (templatePosition, template) => {
        props.addTemplate(templatePosition, template)
    }
    const changeTemplate = (templateId, template) => {
        props.updateTemplate(templateId, template)
    }
    const deleteTemplate = templateId => {
        props.deleteTemplate(templateId)
    }

    useEffect(() => {
        props.toggleTrainingPage(false)
        let isCancelled = false
        const fetchTemplateContent = async () => {
            try {
                const contentResponse = await trackPromise(
                    MeshContent.fetchContent(contentId)
                )
                localStorage.setItem('menu', JSON.stringify(contentResponse.menu))

                props.setContent({ ...contentResponse }, false)
                props.setContent({ ...contentResponse }, true)
            } catch (e) {
                console.log(e)
            }
        }
        !isCancelled && fetchTemplateContent()
        return () => {
            props.setContentMenu(undefined, false)
            isCancelled = true
        }
    }, [contentId])

    useEffect(() => {
        props.setIsHomePage(content.name === 'homeContent')
        props.toggleTrainingPage(content.name === 'trainingScheduleContent')
    }, [content])

    const showComponentWithEdit = (templatePosition, template, componentToShow) => {
        return (
            <CmsTemplatesBars
                key={template.id + '-edit'}
                template={template}
                templatePosition={templatePosition}
                onAdd={template => addTemplate(templatePosition + 1, template)}
                onChange={changeTemplate}
                onDelete={deleteTemplate}
                isEditModeOn={isEditModeOn}
            >
                {componentToShow}
            </CmsTemplatesBars>
        )
    }

    return (
        <>
            {content &&
                content.templates &&
                content.templates.filter((t) => t.type.includes(CONTENT_FOOTER) || t.type.includes(CONTENT_SOCIAL_MEDIA)).length === 0 &&
                <Helmet>
                    <link rel="canonical" href={currentUrl}/>
                </Helmet>}
            {props.children}
            <div className="main-content">
                {content && content.templates &&
            content.templates.map((template, index) => {
                let newsHomeButton = null
                let sportPicturesContent = null
                let homeSponsorsContent = null
                let photoContent = null
                let bannerContent = null
                let photoBannerContent = null
                let accordionContent = null
                let actionButtonBannerContent = null
                let personalContent = null
                let textAreaContent = null
                let contactInfo = null
                let instagramData = null

                switch (template.type) {
                case templateTypes.CARROUSEL:
                    return showComponentWithEdit(
                        index,
                        template,
                        <HomeBanner
                            key={template.id}
                            homeBannerData={template.elements}
                            reducedElementsPro={reducedElementsPro}
                            fields={template.fields}
                        />
                    )

                case templateTypes.GREY_BANNER:
                    return template.elements.map((element) => {
                        const greyBannerProps = reducedElementsPro(
                            element,
                            template.fields
                        )
                        return showComponentWithEdit(
                            index,
                            template,
                            <GreyBanner key={element.id} {...greyBannerProps} />
                        )
                    })

                case templateTypes.GRID:
                    newsHomeButton = reducedElementsPro(
                        template.elements[0],
                        template.fields
                    )
                    return showComponentWithEdit(
                        index,
                        template,
                        <HomeNews key={template.id} newsHomeButton={newsHomeButton}/>
                    )

                case templateTypes.FULL_GRID:
                    return <NewsTemplate key={template.id}/>

                case templateTypes.INSTAGRAM:
                    instagramData = reducedElementsPro(
                        template.elements[0],
                        template.fields,
                        null,
                        'name',
                        true
                    )
                    return showComponentWithEdit(
                        index,
                        template,
                        <Instagram key={template.id} {...instagramData}/>
                    )

                case templateTypes.SMALL_CARROUSEL:
                    sportPicturesContent = template.elements.map((element) =>
                        reducedElementsPro(element, template.fields)
                    )
                    return showComponentWithEdit(
                        index,
                        template,
                        <SportPicturesBanner
                            key={template.id}
                            sportPicturesContent={sportPicturesContent}
                        />
                    )

                case templateTypes.PARALLAX:
                    return template.elements.map((element) => {
                        const parallaxContent = reducedElementsPro(
                            element,
                            template.fields
                        )
                        return showComponentWithEdit(
                            index,
                            template,
                            <HomeAboutUs
                                key={template.id}
                                parallaxContent={parallaxContent}
                            />
                        )
                    })

                case templateTypes.MARQUESINA:
                    homeSponsorsContent = template.elements.map((element) =>
                        reducedElementsPro(element, template.fields)
                    )
                    return showComponentWithEdit(
                        index,
                        template,
                        <HomeSponsors
                            key={template.id}
                            name={template.name}
                            homeSponsorsContent={homeSponsorsContent}
                        />
                    )

                case templateTypes.IMAGE_LIBRARY:
                    photoContent = template.elements.map((element) =>
                        reducedElementsPro(element, template.fields)
                    )
                    return showComponentWithEdit(
                        index,
                        template,
                        <PhotoGalleryTemplate
                            key={template.id}
                            photoContent={photoContent}
                        />
                    )

                case templateTypes.BANNER_IMAGE_LIBRARY:
                    photoBannerContent = template.elements.map((element) =>
                        reducedElementsPro(element, template.fields)
                    )
                    return showComponentWithEdit(
                        index,
                        template,
                        <PhotoGalleryBanner
                            key={template.id}
                            photoContent={photoBannerContent}
                        />
                    )

                case templateTypes.COMMON_BANNER:
                    bannerContent = template.elements.map((element) =>
                        reducedElementsPro(element, template.fields)
                    )[0]
                    return showComponentWithEdit(
                        index,
                        template,
                        <CommonBanner
                            backgroundImage={bannerContent && bannerContent.PHOTO ? bannerContent.PHOTO : profileImage}
                            key={template.id}
                            pageTitle={bannerContent && bannerContent.H1}
                            text={bannerContent && bannerContent.H2}
                        />
                    )

                case templateTypes.ACCORDION:
                    accordionContent = template.elements.map((element) =>
                        reducedElementsPro(element, template.fields)
                    )
                    return showComponentWithEdit(
                        index,
                        template,
                        <AccordionTemplate
                            key={template.id}
                            accordionContent={accordionContent}
                        />
                    )

                case templateTypes.ACTION_BUTTON_BANNER:
                    actionButtonBannerContent = template.elements.map(
                        (element) => reducedElementsPro(element, template.fields)
                    )
                    return showComponentWithEdit(
                        index,
                        template,
                        <ActionButtonBanner
                            key={template.id}
                            actionButtonBannerContent={actionButtonBannerContent}
                        />
                    )

                case templateTypes.PROFILES: {
                    const profilesContent = template.elements.map((element) =>
                        reducedElementsPro(element, template.fields)
                    )
                    return showComponentWithEdit(
                        index,
                        template,
                        <ProfilesTemplate
                            key={template.id}
                            profilesContent={profilesContent}
                        />
                    )
                }

                case templateTypes.PERSONAL_INFO:
                    personalContent = template.elements.map((element) =>
                        reducedElementsPro(element, template.fields)
                    )
                    return (
                        <MembersAreaContainer key={template.id} contentData={personalContent}/>
                    )

                case templateTypes.TEXT_AREA:
                    textAreaContent = template.elements.map((element) =>
                        reducedElementsPro(element, template.fields)
                    )

                    return showComponentWithEdit(
                        index,
                        template,
                        <TextTemplate
                            key={template.id}
                            textContent={textAreaContent[0]?.TEXT_EDITOR}
                        />
                    )

                case templateTypes.VIDEO:
                    return showComponentWithEdit(
                        index,
                        template,
                        <VideoTemplate
                            videos={template.elements}
                        />
                    )

                case templateTypes.CONTACT:
                    contactInfo = template.elements.map((element) =>
                        reducedElementsPro(element, template.fields)
                    )
                    return (
                        <ContactTemplate
                            key={template.id}
                            pageName={content.name}
                            contactName={template.name}
                            contactInfo={contactInfo}/>
                    )

                case templateTypes.TRAINING_SCHEDULE:
                    return showComponentWithEdit(
                        index,
                        template,
                        <TrainingPage key={template.id}/>
                    )

                case templateTypes.COURSE: {
                    const courseInfoProps = adaptCourseData(template, true)

                    return showComponentWithEdit(
                        index,
                        template,
                        <CourseInfoContainer
                            key={template.id}
                            {...courseInfoProps}
                            categoryId={template.metadata?.categoryId}
                            eventsGroupId={template.metadata?.eventsGroupId}
                            contentId={content.id}
                        />
                    )
                }

                case templateTypes.MEMBERS_FORM:
                    return <MembersForm key={template.id}/>

                default:
                    return <div key={template.id}/>
                }
            })}
            </div>
        </>
    )
}

export default Template1
