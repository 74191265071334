/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import './App.scss'
import Header from './header/HeaderContainer'
import Navigation from './navigation/navigationContainer'
import Footer from './footer/FooterContainer'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import {
    faAngleDoubleRight,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArrowRight,
    faCertificate,
    faCheck,
    faVideo,
    faLink,
    faUpload,
    faChild,
    faClock,
    faDumbbell,
    faEnvelope,
    faFootballBall,
    faHeartbeat,
    faInfoCircle,
    faCheckCircle,
    faTimesCircle,
    faMapMarkerAlt,
    faMotorcycle,
    faPen,
    faPhone,
    faPrayingHands,
    faRunning,
    faSearch,
    faStar,
    faSwimmer,
    faUserEdit,
    faUser,
    faChevronDown,
    faChevronUp,
    faTrashAlt,
    faEdit,
    faVolleyballBall,
    faStream,
    faAsterisk,
    faHistory,
    faEye,
    faEyeSlash,
    faPlus,
    faMinus,
    faTimes,
    faExclamationTriangle,
    faExclamationCircle,
    faGlobeAmericas,
    faInfo,
    faList
} from '@fortawesome/free-solid-svg-icons'
import MeshContentMenu from './backend/mesh/content_service/MeshContentMenu'
import { responsiveMenuBreakpoint } from './theme/styles/_responsive_breakpoints.scss'
import Mesh from './backend/mesh/Mesh'
import {
    faQuestionCircle,
    faWindowMaximize,
    faUser as faUserReg,
    faImage,
    faFolderOpen,
    faFileAlt,
    faCreditCard,
    faCheckSquare,
    faSquare
} from '@fortawesome/free-regular-svg-icons'
import jwtDecode from 'jwt-decode'
import { useLocation } from 'react-router'
import { MESH_CONFIG, REDIRECT_URL } from './backend/mesh/Constants'
import { initTrainingCategories } from './trainingCategoriesInitializer'
import { userCanWriteCalendar, userCanWriteContent } from './redux/reducers/reducers'
import * as storage from '@utils/storage'
import STORAGE_KEYS from './utils/storageKeys'
import TermsAndConditionsModalContainer
    from './termsModal/TermsAndConditionsModalContainer'

library.add(
    faChild,
    faFootballBall,
    faFacebook,
    faInstagram,
    faTwitter,
    faYoutube,
    faSearch,
    faArrowRight,
    faSwimmer,
    faStar,
    faCertificate,
    faMotorcycle,
    faPrayingHands,
    faHeartbeat,
    faDumbbell,
    faRunning,
    faWindowMaximize,
    faVolleyballBall,
    faAngleDoubleRight,
    faPen,
    faCheck,
    faVideo,
    faLink,
    faUpload,
    faInfoCircle,
    faCheckCircle,
    faTimesCircle,
    faUserEdit,
    faUser,
    faUserReg,
    faAngleRight,
    faAngleLeft,
    faAngleDown,
    faAngleUp,
    faMapMarkerAlt,
    faPhone,
    faEnvelope,
    faClock,
    faChevronDown,
    faChevronUp,
    faTrashAlt,
    faEdit,
    faFolderOpen,
    faImage,
    faStream,
    faAsterisk,
    faHistory,
    faEye,
    faEyeSlash,
    faPlus,
    faMinus,
    faTimes,
    faQuestionCircle,
    faExclamationTriangle,
    faExclamationCircle,
    faSquare,
    faCheckSquare,
    faCreditCard,
    faFileAlt,
    faGlobeAmericas,
    faInfo,
    faList
)

/**
 * @typedef AppProps
 * @memberof App
 * @property {Object} user
 * @property {Object} customNavigation
 * @property {Object} headerMenu
 * @property {Object} topMenu
 * @property {Object} footerMenu
 * @property {function} saveAuthenticatedUser
 */

/**
 * A wrapper component. This will provide a redux context.
 * @class App
 * @category Components
 * @subcategory General
 * @param {AppProps} props
 * @returns {JSX.Element}
 */
const App = (props) => {
    const [menu, setMenu] = useState({})
    const location = useLocation()
    const { user, customNavigation, headerMenu, topMenu, footerMenu, saveAuthenticatedUser } = props
    const isAdminBarVisible = user.role && user.role.permissions && user.role.permissions.includes('WRITE_CONTENT')
    const [fullDesktopMenu, setFullDesktopMenu] = useState(true)
    const [profileOpened, setProfileOpened] = useState(false)

    const responsiveMenu = useCallback(() => {
        const isDesktop = document.body.offsetWidth >= responsiveMenuBreakpoint
        isDesktop ? setFullDesktopMenu(true) : setFullDesktopMenu(false)
        if (isDesktop) setProfileOpened(false)
    }, [])

    window.addEventListener('load', responsiveMenu)
    const resizeMenu = () => {
        responsiveMenu()
        setHeaderOpened(false)
    }

    window.addEventListener('resize', resizeMenu)

    useEffect(() => {
        responsiveMenu()
    }, [responsiveMenu])

    useEffect(() => {
        /**
         * Login with OAuth code (URL parameter)
         * @param {string} code
         * @returns {Promise<void>}
         * @private
         */
        const _checkQueryCode = async (code) => {
            try {
                const response = await Mesh.fetchToken(code)
                const accessToken = response.data.accessToken
                const { exp, aud, email, role, permissions, id } = jwtDecode(accessToken)
                const isExpired = Math.round(Date.now() / 1000) >= exp

                if (!isExpired) {
                    localStorage.setItem(
                        STORAGE_KEYS.USER,
                        JSON.stringify({
                            id: id,
                            exp,
                            organizationId: aud,
                            email,
                            role: {
                                name: role,
                                permissions: permissions
                            },
                            accessToken
                        })
                    )
                    let url = REDIRECT_URL
                    const storageUrl = storage.get(STORAGE_KEYS.LAST_URL)
                    if (storageUrl) {
                        url = storageUrl
                        storage.remove(STORAGE_KEYS.LAST_URL)
                    }
                    window.location.href = url
                } else {
                    console.log('Your token has expired!')
                }
            } catch (err) {
                console.error(err)
            }
        }

        const currentUser = JSON.parse(localStorage.getItem(STORAGE_KEYS.USER))

        /**
         * Gets the param `code` from the URL
         */
        const query =
            location.search.length > 0
                ? location.search
                    .replace(/^\?/, '')
                    .split('&')
                    .map((it) => it.split('='))
                    .reduce((acc, it) => {
                        acc[it[0]] = it[1]
                        return acc
                    }, {})
                : {}

        if (query.code) {
            _checkQueryCode(query.code)
        } else if (
            currentUser &&
            currentUser.accessToken &&
            currentUser.exp >= Date.now() / 1000
        ) {
            currentUser.email !== MESH_CONFIG.ORGANIZATION.EMAIL && saveAuthenticatedUser(currentUser)
        }
    }, [])

    useEffect(() => {
        let isCancelled = false
        /**
         * Fetches the menu from the content-service
         * @returns {Promise<void>}
         */
        const fetchMenuData = async () => {
            /**
             *
             * @type {MenuData}
             */
            const menuData = await MeshContentMenu.fetchAppMenu()
            if (!isCancelled && menuData.headerMenu) {
                if (userCanWriteContent(user) && userCanWriteCalendar(user)) {
                    const calendarData = [{
                        path: 'header_menu/sports',
                        shouldCreateCategory: true,
                        shouldCreateEventsGroup: false
                    }, {
                        path: 'header_menu/courses',
                        shouldCreateCategory: true,
                        shouldCreateEventsGroup: false
                    }]
                    await initTrainingCategories(menuData.headerMenu, calendarData)
                }
                setMenu(menuData)
                menuData.headerMenu && props.setHeaderMenu(menuData.headerMenu)
                menuData.topMenu && props.setTopMenu(menuData.topMenu)
                menuData.footerMenu && props.setFooterMenu(menuData.footerMenu)
                menuData.socialMediaMenu && props.setSocialMediaMenu(menuData.socialMediaMenu)
            }
        }
        if (user.accessToken) {
            fetchMenuData()
                .catch((error) => {
                    console.warn('Failure caught during menu fetching', { error })
                })
        }

        return () => {
            isCancelled = true
            return null
        }
    }, [user, customNavigation])

    const [headerOpened, setHeaderOpened] = useState(false)

    /**
     * Open and close the responsive menu
     */
    const handleCloseHeader = () => {
        headerOpened &&
        document.body.offsetWidth >= responsiveMenuBreakpoint &&
        setTimeout(() => {
            document.querySelector('#vfl-header .dropdown.show') == null &&
            setHeaderOpened(false)
        }, 15)
    }

    /**
     * Sort menu children based on position property
     * @returns {Menu}
     */
    const sortedHeader = () => {
        return headerMenu?.children?.sort((a, b) => (a.position) - (b.position))
    }

    return (
        <div
            className="App"
            data-testid="App"
            onClick={handleCloseHeader}
            style={{ overflowY: headerOpened ? 'hidden' : 'auto' }}
        >
            <TermsAndConditionsModalContainer isMobile={!fullDesktopMenu}/>

            {headerMenu && topMenu && (
                <Header
                    fullDesktopMenu={fullDesktopMenu}
                    profileOpened={profileOpened}
                    responsiveMenu={responsiveMenu}
                    setProfileOpened={setProfileOpened}
                    topMenu={topMenu.children}
                    headerMenu={sortedHeader()}
                    headerOpened={headerOpened}
                    setHeaderOpened={setHeaderOpened}
                />
            )}
            {Object.keys(menu).length > 0 && <Navigation user={user} customNavigation={customNavigation} menu={menu} className={!!isAdminBarVisible && 'body-admin-margin'}/>}
            {footerMenu && <Footer footerMenu={footerMenu} socialMediaMenu={menu.socialMediaMenu}/>}
        </div>
    )
}

export default App
